import { KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Models/Urls';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PortalInfoService {
  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient,
    private keycloakService: KeycloakService
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getPortalInfo() {
    return this.httpclient.get<any>(this.baseUrl + '/api/portalinfo')
                          .pipe(catchError(this.handleError));
  }

  postPortalInfo(pInfo) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.baseUrl + '/api/portalinfo', pInfo, options)
                          .pipe(catchError(this.handleError));
  }

  putPortalInfo(pInfo) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.baseUrl + '/api/portalinfo/' + pInfo.PortalId, pInfo, options)
                          .pipe(
                            catchError(this.handleError)
                          );
  }

  deletePortalInfo(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete(this.baseUrl + '/api/portalinfo/' + id, options)
                          .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }
}
