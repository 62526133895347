import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Urls } from 'src/app/Models/Urls';

@Injectable({
  providedIn: 'root'
})
export class GroupInstanceService {

  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getGroupInstances(inst: number) {
    return this.httpclient.get<any>(this.baseUrl + '/api/groupinstance/' + inst)
                          .pipe(catchError(this.handleError));
  }

  postGroupInstance(inst: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.baseUrl + '/api/groupinstance', inst, options)
                    .pipe(catchError(this.handleError));
  }

  deleteGroupInstance(inst: any) {
    const str = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete(this.baseUrl + '/api/groupinstance/' + inst, options)
                    .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }
}
