import { Language } from './../../Models/language';
import { LanguageService } from './../../Services/language/language.service';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { Title } from '@angular/platform-browser';
import { Home1Component } from '../home1/home1.component';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/Services/auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();
  organisation: any;

  langs = [];
  lang: any;
  isAccount = false;
  isLanguage: boolean;

  loading = false;

  portalHeaderColor = 'transparent';

  icon: any;

  constructor(
    public translate: TranslateService,
    // private keycloakService: KeycloakService,
    private router: Router,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private home: Home1Component,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private injector: Injector,
    private auth: AuthService,
    private language: LanguageService,
    private notificationSvc: NotificationService,
    private app: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.icon = app.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    this.loadUserProfile();

    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const og = usr.organisationName;
    if (og === 'ORG_SUPER_ADMIN') {
      this.isLanguage = true;
      this.isManage = true;
    }

    this.getLangs();
   }

  ngOnInit() {
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  trans(value) {
    this.translate.use(value);
    localStorage.setItem('language', value);
    const pass = 'ranitessarldev2019';
    const enclanguage = CryptoJS.AES.encrypt(value, pass).toString();
    const domain = environment.domain;
    this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', domain, false, 'Lax');
  }

  getLangs() {
    const langs = this.translate.getLangs();
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < langs.length; i++) {
      this.langs.push({label: langs[i], value: langs[i]});
    }

    const lang = localStorage.getItem('language');
    // const lang = this.translate.currentLang;
    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < this.langs.length; j++) {
      if (this.langs[j].value === lang) {
        this.lang = this.langs[j].value;
      }
    }
  }

  transl() {
    // this.translate.use(this.lang);
    localStorage.setItem('language', this.lang);
    const pass = 'ranitessarldev2019';
    const enclanguage = CryptoJS.AES.encrypt(this.lang, pass).toString();
    // const enclanguage = this.lang;
    const domain = environment.domain;
    this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', domain, false, 'Lax');

    const v = new Variables();
    const va = localStorage.getItem('ui');
    const u = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const ui = u;
    const uid = +ui;

    const lang = new Language();
    lang.UserId = uid;
    lang.Lang = this.lang;

    this.loading = true;
    // Remove this field later
    lang.UserId = 1;
    // tslint:disable-next-line: deprecation
    this.language.postLanguage(lang).subscribe((res) => {
      this.loading = false;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.SAVED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
      });
    }, err => this.handleError(err));
  }

  handleError(err) {
    this.loading = false;
  }





  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }
  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }
}
