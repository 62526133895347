import { DomSanitizer } from '@angular/platform-browser';
import { Variables } from './../../Models/variables';
import { GroupsService } from './../../Services/groups/groups.service';
import { PermissionService } from './../../Services/permission/permission.service';
import { ImageService } from './../../Services/image/image.service';
import { LanguageService } from './../../Services/language/language.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { UsersService } from 'src/app/Services/users/users.service';
import { User } from 'src/app/Models/User';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { UserUpdater, KeyValue } from 'src/app/Models/KeyValue';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  EmployeeId: number;
  Pin: string;

  load = false;
  loading = false;
  isVis = false;

  userform: FormGroup;

  orgs = [];
  org: any;

  oid = 0;

  image: any;
  icon: any;

  domain = environment.domain;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationSvc: NotificationService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private userService: UsersService,
    private location: Location,
    private cookieService: CookieService,
    private language: LanguageService,
    private fb: FormBuilder,
    private imageService: ImageService,
    private permService: PermissionService,
    private groupService: GroupsService,
    private domSanitizer: DomSanitizer,
    private keycloakService: KeycloakService
    ) {
      localStorage.clear();
      this.cookieService.set('goepla-portal-language', '', 1000, '/', this.domain, false, 'Lax');
      this.cookieService.set('goepla-portal-user-id', '', 1000, '/', this.domain, false, 'Lax');
      this.cookieService.set('goepla-portal-organisation-id', '', 1000, '/', this.domain, false, 'Lax');

      this.userform = this.fb.group({
        // selectorg: new FormControl(null, Validators.required),
        empid: new FormControl('', Validators.required),
        pin: new FormControl('', Validators.required)
      });

      // this.getImages();
     }

     getLanguage() {
      // Replace the user id with the users user id later
      this.language.getLanguage(1).subscribe((lang) => {
        if (lang.userId > 0) {
          const pass = 'ranitessarldev2019';
          const enclanguage = CryptoJS.AES.encrypt(lang.lang, pass).toString();
          // const domain = environment.domain;

          this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', this.domain, false, 'Lax');
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
          this.isVis = true;
          this.load = false;
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
          this.isVis = true;
          this.load = false;
        }
      }, err => this.handleError(err));
     }

     getOrganisations() {
       this.userService.getOrganisations().subscribe((os) => {
         if (os != null && os.length > 0) {
          for (const i of os) {
            this.orgs.push({ label: i.organisationName, value: i });
          }
         }
       });
     }

     getImages() {
       this.load = true;
       this.imageService.getImages().subscribe((res) => {
         this.getLanguage();
         if (res !== null) {
           for (const img of res) {
             if (img.name === 'icon') {
              this.icon = this.domSanitizer.bypassSecurityTrustUrl(img.img);
              // this.icon = img.img;
              if (img.img === null || img.img === '') {
                this.icon = 'assets/img.jpg';
              }
             } else if (img.name === 'image') {
              this.image = this.domSanitizer.bypassSecurityTrustUrl(img.img);
              // this.image = img.img;
              if (img.img === null || img.img === '') {
                this.image = 'assets/img.jpg';
              }
            }
           }
         }
       }, err => this.handleError(err));
     }

  async ngOnInit() {
  //   if (this.authService.isLoggedIn) {
  //     this.router.navigate(['/home']);
  //  } else {
  //    await this.getUser();
  //  }

   await this.getUser();
  }

  async login() {
    this.loading = true;
    let eml = null;
    let eid = 0;
    if (this.EmployeeId === undefined || this.EmployeeId.toString().trim() === null
         || this.EmployeeId === 0 || this.EmployeeId.toString().trim() === '' || this.EmployeeId.toString().trim() === '0') {
      this.translate.get('GENERAL.ENTEREMPLOYEEIDOREMAIL').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
      return;
     } else {
      if (isNaN(this.EmployeeId)) {
        if (!this.EmployeeId.toString().match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
          this.translate.get('GENERAL.EMAILNOTINCORRECTFORMAT').subscribe((resp: string) => {
            this.confirm(resp);
          });
          this.loading = false;
          return;
        } else {
          eml = this.EmployeeId;
        }
      } else {
        eid = this.EmployeeId;
      }
     }
    if (this.Pin === undefined || this.Pin === null || this.Pin.trim() === '') {
      this.translate.get('GENERAL.ENTERPIN').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
      return;
    }

    const usr = new User();
    usr.EmployeeId = this.EmployeeId;
    usr.Pin = this.Pin;

    this.userService.checkUser(this.oid, eid, eml, null, null, this.Pin).subscribe((response) => {
      if (response === true) {
        this.userService.getUser(eid, 0, eml, null).subscribe(async (ress) => {
          // Replace the user id with the users user id later
          this.language.getLanguage(1).subscribe((lang) => {
            if (lang.userId > 0) {
              const pass = 'ranitessarldev2019';
              const enclanguage = CryptoJS.AES.encrypt(lang.lang, pass).toString();
              const dom = environment.domain;
              this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', dom, false, 'Lax');
              localStorage.setItem('language', lang.lang);
            }
          }, err => this.handleError(err));

          // const domain = environment.domain;
          // this.cookieService.set('goepla-portal-login', 'true', 0.042, '/', domain, false, 'Lax');

          // localStorage.setItem('organisation-id', ress.organisationId);
          // localStorage.setItem('org-id', ress.organisationId);
          // localStorage.setItem('user-id', ress.userId);

          const v = new Variables();
          const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
          const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
          localStorage.setItem('oi', oid);
          localStorage.setItem('ui', uid);

          await this.getGroups();
        }, err => {
          this.handleError(err);
        });
      } else {
        this.translate.get('GENERAL.INVALIDUSER').subscribe((resp: string) => {
          this.confirm(resp);
          this.loading = false;
        });
      }
    }, err => this.handleError(err));
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    this.userService.getUser(0, 0, eml, null).subscribe(async (ress) => {
      // Replace the user id with the users user id later
      this.language.getLanguage(1).subscribe((lang) => {
        if (lang.userId > 0) {
          const pass = 'ranitessarldev2019';
          const enclanguage = CryptoJS.AES.encrypt(lang.lang, pass).toString();
          const dom = environment.domain;
          this.cookieService.set('goepla-portal-language', enclanguage, 1000, '/', dom, false, 'Lax');
          localStorage.setItem('language', lang.lang);
        }
      }, err => this.handleError(err));

      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.getGroups();
    }, err => {
      this.handleError(err);
    });
  }

  async getGroups() {
    const v = new Variables();
    const va = localStorage.getItem('ui');
    const ui = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    const uid = +ui;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs);
    // localStorage.setItem('my-groups', mygroups);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    // localStorage.setItem('user-name', username);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(user, groupids);
  }

  async getPermissions(user, groupids) {
    const permissions = [];
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    const usr = new User();
    usr.EmployeeId = this.EmployeeId;
    usr.Pin = this.Pin;

    const v = new Variables();
    const us = CryptoJS.AES.encrypt(JSON.stringify(user), v.pass);
    localStorage.setItem('eu', us);

    await this.setLastLogin();

    // localStorage.setItem('ext_user', JSON.stringify(user));
    this.authService.login(usr);
    window.location.reload();
    this.loading = false;
  }

  async setLastLogin() {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const upt = new UserUpdater();
    upt.Id = usr.userId;
    upt.Values = [];

    const llogin = new KeyValue();
    llogin.Key = 'lst_login';
    upt.Values.push(llogin);

    await this.userService.putUser(upt.Id, upt).toPromise().catch(err => this.handleError(err));
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    console.log(err);
    this.loading = false;
    this.load = false;

    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    this.isVis = true;

    if (err.error.Message === 'Invalid employee id') {
      this.translate.get('GENERAL.INVALIDUSER').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    }

    if (err.error.Message === 'Incorrect password') {
      this.translate.get('GENERAL.INVALIDUSER').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    }

    if (err.error.Message === 'More than one employee ids') {
      this.translate.get('GENERAL.EMPLOYEEIDCANNOTBEUSED').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    }

    if (err.error.Message === 'Invalid email') {
      this.translate.get('GENERAL.INVALIDUSER').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    }
  }

}
