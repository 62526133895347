import { AuthService } from './../../Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { AppComponent } from './../../app.component';
import { GroupsService } from './../../Services/groups/groups.service';
import { UserGroupsService } from './../../Services/user-groups/user-groups.service';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, Injector } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UserappinstanceService } from 'src/app/Services/userappinstnce/userappinstance.service';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { Urls } from 'src/app/Models/Urls';
import { KeycloakProfile } from 'keycloak-js';
import { UsersService } from 'src/app/Services/users/users.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-home1',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.css']
})
export class Home1Component implements OnInit {
  organisation: string;

  title: string;
  fullName: string;

  userDetails: KeycloakProfile;
  isManage = false;
  http: any;

  urls = new Urls();

  accs = [];
  acc: any;

  inter: any;
  inter2: any;
  inter3: any;

  isins = false;

drawer: string;
div: string;
drawer2: string;
holder: string;



  instances = [];
  instances2: any;
  ids: any[] = [];

  previous: string;

  isHomeVisible: string;
  isMainVisible: string;

  browser = false;
  insts = [];
  isAccount = false;
  isload: boolean;
  isLanguage: boolean;

  color = 'white';

  items = [];

  issmallmenu = false;

  variables = new Variables();

  instcount = 0;

  portalHeaderColor = 'transparent';

  icon: any;

  constructor(
    private sanitizer: DomSanitizer,
    // private keycloakService: KeycloakService,
    private userInstanceService: UserappinstanceService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    public translate: TranslateService,
    private portalinfo: PortalInfoService,
    private userService: UsersService,
    private titleService: Title,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private usrgrp: UserGroupsService,
    private group: GroupsService,
    private injector: Injector,
    private auth: AuthService,
    private keycloakService: KeycloakService,
    private app: AppComponent
    ) {
      translate.addLangs(['English', 'French']);
      translate.setDefaultLang('English');

      let browserLang = translate.getBrowserLang();
      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      router.navigateByUrl('/home/');

      this.icon = app.icon;
    }

     async ngOnInit() {
      if (localStorage.getItem('portal-title') === null) {
        this.portalinfo.getPortalInfo().subscribe((res) => {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            this.title = res[i].portalTitle;
            localStorage.setItem('portal-title', res[i].portalTitle);
            localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
            this.portalHeaderColor = res[i].portalHeaderColor;
            localStorage.setItem('headercolor', res[i].portalHeaderColor);

            this.titleService.setTitle(res[i].portalTitle);
          }
        }, err => this.handleError(err));
      } else {
        this.title = localStorage.getItem('portal-title');
        this.titleService.setTitle(this.title);

        this.portalHeaderColor = localStorage.getItem('headercolor');
      }

      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();
      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const usr = JSON.parse(eu);

      const og = usr.organisationName;
      if (og === 'ORG_SUPER_ADMIN') {
        this.isLanguage = true;
        this.isManage = true;
      }

      if (this.router.url === '/home' || this.router.url === '/home/' || this.router.url === '/' || this.router.url === '') {
        this.div = 'true';
        this.drawer = null;
      } else {
        this.div = null;
        this.drawer = 'true';
      }

      // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      const isChrome = true;
      if (isChrome === true) {
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        // const version = raw ? parseInt(raw[2], 10) : false;
        const version = 80;
        if (version >= 80) {
          this.browser = true;
          localStorage.setItem('browser', 'true');
        } else {
          this.browser = false;
          localStorage.setItem('browser', 'false');
          this.translate.get('ALERT.BROWSERCHECK').subscribe((resp) => {
            this.confirm(resp);
          });
          return;
        }
      } else {
        this.browser = false;
        localStorage.setItem('browser', 'false');
        this.translate.get('ALERT.BROWSERCHECK').subscribe((resp) => {
          this.confirm(resp);
        });
        return;
      }

      this.loadUserProfile();
     }

     loadInstances(name, group) {
      this.getUserAppInstances(name, group);
     }

    showHome() {
      this.color = 'white';
      this.isload = false;
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const usr = JSON.parse(eu);

      if (va !== null) {
        const extUser = usr;
        if (extUser.userId !== 0) {
          this.isHomeVisible = 'true';
          this.isMainVisible = null;

          this.drawer = null;
          this.drawer2 = null;
          this.div = 'true';
          if (this.instances2 != null && this.instances2.length > 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.instances2.length; i++) {
              this.instances2[i].isVisible = false;
            }
          }
          this.router.navigateByUrl('/home/');
        }
      }
     }

     showMain() {
      this.isHomeVisible = null;
      this.isMainVisible = 'true';
    }

    toggleOn() {
      this.drawer = null;
      this.div = 'true';
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.instances2.length; i++) {
        this.instances2[i].isVisible = false;
      }
    }

    toggleOff() {
      this.drawer = 'true';
      this.div = null;
    }

  async getUserAppInstances(name, group) {
    this.insts = [];
    this.instances = [];
    this.items = [];
    const groups: string[] = [];

    if (group != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < group.length; i++) {
        // groups.push(group[i].groupName);
        groups.push(group[i].groupId.toString());
      }
    }

    this.instcount = 0;
    const igid = [];

    await this.userInstanceService.getUserAppInstancesByUser(name).subscribe(async (instance1: any[]) => {
      if (instance1 !== undefined && instance1 !== null && instance1.length > 0) {
        for (const i of instance1) {
          if (!igid.includes(i.instanceId)) {
            this.instcount += 1;
            igid.push(i.instanceId);
          }
        }
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < instance1.length; i++) {
        const image = this.domSanitizer.bypassSecurityTrustUrl(instance1[i].instanceIcon);
        instance1[i].IIcon = image;
        if (this.ids.includes(instance1[i].instanceId) === false) {

          let url = '';
          if (!/^http[s]?:\/\//.test(instance1[i].appUrl)) {
              url += 'http://';
          }

          url += instance1[i].appUrl;
          instance1[i].appUrl = url;

          this.ids.push(instance1[i].instanceId);
          this.insts.push(instance1[i]);
          this.instances.push(instance1[i]);
          this.items.push({ label: instance1[i].instanceName, value: instance1[i],
             routerLink: ['/home/' + instance1[i].instanceName],
             icon: this.domSanitizer.bypassSecurityTrustUrl(instance1[i].instanceIcon),
             command: (event) => { this.show(instance1[i]); } });
        }
      }

      if (groups !== null && groups.length !== 0) {
        const igrps = [];
        let counting = 1;
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < groups.length; j++) {
          // tslint:disable-next-line: no-shadowed-variable
          await this.userInstanceService.getUserAppInstancesByGroup(groups[j]).subscribe((instance: any[]) => {
            if (instance !== undefined && instance !== null && instance.length > 0) {
              for (const i of instance) {
                if (!igid.includes(i.instanceId)) {
                  this.instcount += 1;
                  igid.push(i.instanceId);
                }
              }
            }
            let count = 1;
            if (instance != null && instance.length > 0) {
              // tslint:disable-next-line: prefer-for-of
              for (let k = 0; k < instance.length; k++) {
                // tslint:disable-next-line: no-shadowed-variable
                const image = this.domSanitizer.bypassSecurityTrustUrl(instance[k].instanceIcon);
                instance[k].IIcon = image;
                if (this.ids.includes(instance[k].instanceId) === false) {
                  let url = '';
                  if (!/^http[s]?:\/\//.test(instance[k].appUrl)) {
                      url += 'http://';
                  }

                  url += instance[k].appUrl;
                  instance[k].appUrl = url;

                  this.ids.push(instance[k].instanceId);
                  this.insts.push(instance[k]);
                  this.instances.push(instance[k]);
                  this.items.push({ label: instance[k].instanceName, value: instance[k],
                     routerLink: ['/home/' + instance[k].instanceName],
                     icon: this.domSanitizer.bypassSecurityTrustUrl(instance[k].instanceIcon),
                     command: (event) => { this.show(instance[k]); } });
                  if (count === instance.length && counting === groups.length) {
                    this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                    let counter = 1;
                    // tslint:disable-next-line: prefer-for-of
                    for (let m = 0; m < this.insts.length; m++) {
                      // this.instances.push(this.insts[m]);
                      if (counter === this.insts.length) {
                        // localStorage.setItem('instances', JSON.stringify(this.instances));
                        this.load();
                      }
                      counter += 1;
                    }
                  }

                  if (groups.length === counting) {
                    // this.instances = [];
                    this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                    let counter = 1;
                    for (const ins of this.insts) {
                      if (this.ids.includes(ins.instanceId) === false) {
                        // this.instances.push(ins);
                        this.ids.push(ins.instanceId);
                      }
                      if (counter === this.insts.length) {
                        // localStorage.setItem('instances', JSON.stringify(this.instances));
                        this.load();
                      }
                      counter += 1;
                    }
                  }

                  if (count === instance.length) {
                    counting += 1;
                  }
                  count += 1;
                } else {
                  if (count === instance.length && counting === groups.length) {
                    this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                    let counter = 1;
                    // tslint:disable-next-line: prefer-for-of
                    for (let m = 0; m < this.insts.length; m++) {
                      // this.instances.push(this.insts[m]);
                      if (counter === this.insts.length) {
                        // localStorage.setItem('instances', JSON.stringify(this.instances));
                        this.load();
                      }
                      counter += 1;
                    }
                  }

                  if (groups.length === counting) {
                    // this.instances = [];
                    this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                    let counter = 1;
                    for (const ins of this.insts) {
                      if (this.ids.includes(ins.instanceId) === false) {
                        // this.instances.push(ins);
                        this.ids.push(ins.instanceId);
                      }
                      if (counter === this.insts.length) {
                        // localStorage.setItem('instances', JSON.stringify(this.instances));
                        this.load();
                      }
                      counter += 1;
                    }
                  }

                  if (count === instance.length) {
                    counting += 1;
                  }
                  count += 1;
                }
              }
            } else {
              if (counting === groups.length) {
                this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                let counter = 1;
                if (this.insts.length > 0) {
                  // tslint:disable-next-line: prefer-for-of
                  for (let m = 0; m < this.insts.length; m++) {
                    if (this.ids.includes(this.insts[m].instanceId) === false) {
                      // this.instances.push(this.insts[m]);
                      this.ids.push(this.insts[m].instanceId);
                    }
                    if (counter === this.insts.length) {
                      // localStorage.setItem('instances', JSON.stringify(this.instances));
                      this.load();
                    }
                    counter += 1;
                  }
                } else {
                  this.load();
                }
              }

              counting += 1;
            }
          });
        }
      } else {
        if (this.insts != null && this.insts.length > 0) {
          this.insts.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
          let counter = 1;
          // tslint:disable-next-line: prefer-for-of
          for (let m = 0; m < this.insts.length; m++) {
            if (this.ids.includes(this.insts[m].instanceId) === false) {
              // this.instances.push(this.insts[m]);
              this.ids.push(this.insts[m].instanceId);
            }
            if (counter === this.insts.length) {
              // localStorage.setItem('instances', JSON.stringify(this.instances));
              this.load();
            }
            counter += 1;
          }
        } else {
          this.load();
        }
      }
    });
  }

  save(instance) {
    localStorage.setItem('instance', JSON.stringify(instance));
    localStorage.setItem('instances', JSON.stringify(this.instances));

    if (instance.instanceTarget === 'new_tab') {
      window.open(instance.appUrl);
    } else if (instance.instanceTarget === 'content') {
      this.router.navigateByUrl('/home/' + instance.instanceName);

      this.isHomeVisible = null;
      this.isMainVisible = 'true';
    }
  }

  saver(instance) {
    if (instance.instanceTarget === 'new_tab') {
      window.open(instance.appUrl);
    } else if (instance.instanceTarget === 'content') {
      // this.toggleOff();
      this.color = '#f2f2f2';
      this.show(instance);
    }
  }

  async changePass() {
    const res = await this.router.navigateByUrl('/change-password');
    if (res === false) {
      await this.router.navigateByUrl('/change-password');
    }
  }

  async changePin() {
    const res = await this.router.navigateByUrl('/change-pin');
    if (res === false) {
      await this.router.navigateByUrl('/change-pin');
    }
  }

  scroll(elm) {
    const el = document.getElementById(elm);
    if (el != null) {
      el.scrollIntoView({block: 'center'});
      clearInterval(this.inter);
    }
  }

  scroll2(elm) {
    const el = document.getElementById(elm);
    if (el != null) {
      el.scrollIntoView({block: 'center'});
      clearInterval(this.inter2);
    }
  }

  scroll3(elm) {
    const el = document.getElementById(elm);
    if (el != null) {
      el.scrollIntoView({block: 'center'});
      clearInterval(this.inter3);
    }
  }

  load() {
    // this.instances = this.insts;
    this.instances.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
    this.items.sort((a, b) => a.label.localeCompare(b.label));
    localStorage.setItem('instances', JSON.stringify(this.instances));
    if (this.instances.length === 0) {
    } else {
      const instance = JSON.parse(localStorage.getItem('instance'));
      const insts = JSON.parse(localStorage.getItem('instances'));

      if (insts != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < insts.length; i++) {
          const image = this.domSanitizer.bypassSecurityTrustUrl(insts[i].instanceIcon);
          insts[i].IIcon = image;

          insts[i].isVisible = false;
          insts[i].fUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
          insts[i].first = true;
        }
      }

      this.instances2 = insts;
      // this.show(this.instances2[0]);
      if (this.instances2 !== undefined && this.instances2 !== null && this.instances2.length > 0) {
        if (this.instcount === 1) {
          this.saver(this.instances[0]);
        } else {
        }
      }
    }

    this.isins = true;
  }

  set(instance: any) {
    const to = setInterval(() => {
      try {
        document.getElementById(instance.instanceName + instance.instanceName).onload = (() => {
          this.isload = false;
          clearInterval(to);
        });
      } catch (error) {
        // this.isload = false;
      }
    }, 1);
  }

  show(instance) {
    this.isload = true;
    this.issmallmenu = false;

    this.set(instance);

    if (this.holder === 'drawer') {
      this.drawer = 'true';
      this.drawer2 = null;
    } else if (this.holder === 'drawer2') {
      this.drawer2 = 'true';
      this.drawer = null;
    } else {
      this.drawer2 = null;
      this.drawer = 'true';
    }
    this.div = null;
    if (instance.instanceTarget === 'content') {
      this.previous = instance.instanceName;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.instances2.length; i++) {
        if (this.instances2[i].instanceName === instance.instanceName) {
          if (this.instances2[i].first === true) {
            this.instances2[i].fUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.instances[i].appUrl);
            this.instances2[i].first = false;
          } else {
            this.isload = false;
          }
          this.instances2[i].isVisible = true;
        } else {
          this.instances2[i].isVisible = false;
        }
      }
      this.router.navigateByUrl('/home/' + instance.instanceName);
      this.inter = setInterval(() => {
        this.scroll(instance.instanceId);
      }, 100);
    } else {
      this.isload = false;
      this.router.navigateByUrl('/home/' + this.previous);
      window.open(instance.appUrl);
    }

    localStorage.setItem('instance', JSON.stringify(instance));
  }

  minimize() {
    const ins = JSON.parse(localStorage.getItem('instance'));
    this.drawer = null;
    this.drawer2 = 'true';
    this.holder = 'drawer2';
    this.inter2 = setInterval(() => {
      this.scroll2(ins.instanceId + ' ' + ins.instanceId);
    }, 100);
  }

  maximize() {
    const ins = JSON.parse(localStorage.getItem('instance'));
    this.drawer = 'true';
    this.drawer2 = null;
    this.holder = 'drawer';
    this.inter3 = setInterval(() => {
      this.scroll3(ins.instanceId);
    }, 100);
  }

  menuClicked() {
    this.issmallmenu = !this.issmallmenu;
  }

  gotoAccount() {
    window.open(this.urls.keycloakUrl + '/auth/realms/' + this.variables.realm + '/account');
  }

  async gotoLanguage() {
    const res = await this.router.navigateByUrl('/language');
    if (res === false) {
      await this.router.navigateByUrl('/language');
    }
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');
    this.cookieService.delete('goepla-portal-login');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;

        const extId = keycloakService.getKeycloakInstance().tokenParsed.sub;
        this.userService.getExternalUserById(extId).subscribe((res) => {
          if (res.userId === 0) {
            this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
              this.confirm(resp);
            });
            return;
          } else {
            // localStorage.setItem('ext_user', JSON.stringify(res));
            const v = new Variables();
            const us = CryptoJS.AES.encrypt(JSON.stringify(res), v.pass);
            localStorage.setItem('eu', us);

            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const extUser = usr;
            this.organisation = extUser.organisationName;

            const pass = 'ranitessarldev2019';
            const encorg = CryptoJS.AES.encrypt(extUser.organisationId.toString(), pass).toString();
            const encuser = CryptoJS.AES.encrypt(extUser.userId.toString(), pass).toString();
            const domain = environment.domain;
            // this.cookieService.set('goepla-portal-organisation-id', encorg, 1000, '/', domain, false, 'Lax');
            // this.cookieService.set('goepla-portal-user-id', encuser, 1000, '/', domain, false, 'Lax');

            const name = extUser.firstName + ' ' + extUser.lastName;
            this.fullName = name;
            localStorage.setItem('fullname', name);
            this.usrgrp.getUserGroupsByUserId(extUser.userId).subscribe((resp) => {
              const grp = [];
              let count = 1;
              if (resp != null) {
                // tslint:disable-next-line: prefer-for-of
                for (let m = 0; m < resp.length; m++) {
                  this.group.getGroupByGroupId(resp[m].groupId).subscribe((respp) => {
                    // tslint:disable-next-line: prefer-for-of
                    for (let n = 0; n < respp.length; n++) {
                      grp.push(respp[n]);
                    }
                    if (count === resp.length) {
                      this.loadInstances(name, grp);
                    }
                    count += 1;
                  });
                }
              } else {
                this.loadInstances(name, null);
              }
            });
          }
        }, err => {
          this.handleError(err);
        });
      });
    } else {
      const v = new Variables();
      let va = localStorage.getItem('eu');
      let eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      let usr = JSON.parse(eu);
      this.userService.getUser(0, usr.userId, null, null).subscribe((res) => {
        if (res.userId === 0) {
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            this.confirm(resp);
          });
          return;
        } else {
          const us = CryptoJS.AES.encrypt(JSON.stringify(res), v.pass);
          localStorage.setItem('eu', us);

          va = localStorage.getItem('eu');
          eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
          usr = JSON.parse(eu);
          const extUser = usr;
          this.organisation = extUser.organisationName;

          const pass = 'ranitessarldev2019';
          const encorg = CryptoJS.AES.encrypt(extUser.organisationId.toString(), pass).toString();
          const encuser = CryptoJS.AES.encrypt(extUser.userId.toString(), pass).toString();
          const domain = environment.domain;
          // this.cookieService.set('goepla-portal-organisation-id', encorg, 1000, '/', domain, false, 'Lax');
          // this.cookieService.set('goepla-portal-user-id', encuser, 1000, '/', domain, false, 'Lax');

          const name = extUser.firstName + ' ' + extUser.lastName;
          this.fullName = name;
          localStorage.setItem('fullname', name);
          this.usrgrp.getUserGroupsByUserId(extUser.userId).subscribe((resp) => {
            const grp = [];
            let count = 1;
            if (resp != null) {
              // tslint:disable-next-line: prefer-for-of
              for (let m = 0; m < resp.length; m++) {
                this.group.getGroupByGroupId(resp[m].groupId).subscribe((respp) => {
                  // tslint:disable-next-line: prefer-for-of
                  for (let n = 0; n < respp.length; n++) {
                    grp.push(respp[n]);
                  }
                  if (count === resp.length) {
                    this.loadInstances(name, grp);
                  }
                  count += 1;
                });
              }
            } else {
              this.loadInstances(name, null);
            }
          });
        }
      }, err => {
        this.handleError(err);
      });
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    console.log(err);
    return;
  }
}
