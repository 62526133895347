import { Icons } from './../../Models/Icons';
import { Location } from '@angular/common';
import { Component, OnInit, Injector } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { MenuItem } from 'primeng/api/menuitem';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Home1Component } from '../home1/home1.component';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { IconsService } from 'src/app/Services/icons/icons.service';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-upload-icon',
  templateUrl: './upload-icon.component.html',
  styleUrls: ['./upload-icon.component.css']
})
export class UploadIconComponent implements OnInit {

  apps = [];

  isLoadMore = true;
  page = 1;
  size = 100;

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();
  organisation: any;

  items: MenuItem[];
  options = [];
  filename: any;
  Icon: any;
  icon: any;
  IconName: any;
  loading: boolean;
  isAccount = false;
  isLanguage: boolean;

  portalHeaderColor = 'transparent';

  hicon: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    // private keycloakService: KeycloakService,
    private titleService: Title,
    private home: Home1Component,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private portalinfo: PortalInfoService,
    private iconsService: IconsService,
    private domSanitizer: DomSanitizer,
    private location: Location,
    private injector: Injector,
    private auth: AuthService,
    private app: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.hicon = app.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

      // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.isManage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.EDIT').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DELETE').subscribe((resp2: string) => {
        this.options = [
          { label: resp1, value: 'Edit' },
          { label: resp2, value: 'Delete' }
        ];
      });
    });

    this.loadUserProfile();
   }

  ngOnInit() {
  }

  onUpload() {
    this.loading = true;
    if (this.Icon === undefined || this.Icon === null || this.Icon.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTICON').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.IconName === undefined || this.IconName === null || this.IconName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERICONNAME').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else {
      const icon = new Icons();
      icon.Icon = this.Icon;
      icon.IconName = this.IconName;

      // tslint:disable-next-line: deprecation
      this.iconsService.postIcon(icon).subscribe((resp) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ICONUPLOADED').subscribe((res: string) => {
          this.notificationSvc.info('', res, 2000);
          this.loading = false;
        });
        this.location.back();
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.Message === '23505: duplicate key value violates unique constraint "t_icon_icon_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ICONNAMEALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
  }







  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

}
