import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Urls } from 'src/app/Models/Urls';
import { catchError } from 'rxjs/operators';
import { ImageUpdater } from 'src/app/Models/KeyValue';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  url = new Urls();
  baseUrl: string;

constructor(
  private httpclient: HttpClient
) {
  this.baseUrl = this.url.baseUrl;
 }

getImages() {
  return this.httpclient.get<any>(this.baseUrl + '/api/image')
                        .pipe(catchError(this.handleError));
}

putImage(image: ImageUpdater) {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.put<any>(this.baseUrl + '/api/image/' + image.Name, image, options)
                        .pipe(
                          catchError(this.handleError)
                        );
}

private handleError(error: any) {
  throw error;
  return error;
}

}
