export class Instance {
    public InstanceId: number;
    public AppId: number;
    public InstanceName: string;
    public InstanceDescription: string;
    public InstanceTarget: string;
    public InstanceIcon: any;
    public InstanceIconName: any;
    public InstanceParameters: string;
}
