import { Location } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { UserUpdater, KeyValue } from 'src/app/Models/KeyValue';
import { Urls } from 'src/app/Models/Urls';
import { Variables } from 'src/app/Models/variables';
import { AppService } from 'src/app/Services/application/app.service';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { UsersService } from 'src/app/Services/users/users.service';
import { environment } from 'src/environments/environment';
import { Home1Component } from '../home1/home1.component';
import * as CryptoJS from 'crypto-js';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit {

  OldPin: string;
  NewPin: string;
  ConfirmPin: string;

  loading = false;
  isCreate = false;
  isCancel = false;

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  isLanguage: boolean;
  http: any;

  urls = new Urls();
  organisation: string;

  items: MenuItem[];

  isAccount = false;

  portalHeaderColor = 'transparent';

  icon: any;

  constructor(
    private router: Router,
    private appservice: AppService,
    private translate: TranslateService,
    // private keycloakService: KeycloakService,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private userService: UsersService,
    private home: Home1Component,
    private cookieService: CookieService,
    private location: Location,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private injector: Injector,
    private auth: AuthService,
    private app: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.icon = app.icon;

    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');

    let browserLang = translate.getBrowserLang();
    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

          // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.isManage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

    this.loadUserProfile();
   }

  ngOnInit() {
  }

  onCancel() {
    this.loading = false;
    this.location.back();
  }

  async Save() {
    // tslint:disable-next-line: max-line-length
    if (this.OldPin === undefined || this.OldPin == null || this.OldPin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERCURRENTPIN').subscribe((res: string) => {
        this.confirm(res);
      });
    // tslint:disable-next-line: max-line-length
    } else if (this.NewPin === undefined || this.NewPin === null || this.NewPin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERNEWPIN').subscribe((res: string) => {
        this.confirm(res);
      });
    } else if (this.ConfirmPin === undefined || this.ConfirmPin === null || this.ConfirmPin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERNEWPINCONFIRMATION').subscribe((res: string) => {
        this.confirm(res);
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.loading = true;
      const res = await this.userService.checkUser(user.organisationId, user.employeeId, user.email, null, null, this.OldPin)
      .toPromise().catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        if (this.NewPin !== this.ConfirmPin) {
          this.loading = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('ALERT.NEWPINSDONOTMATCH').subscribe((resp: string) => {
            this.confirm(resp);
          });
        } else {
          const upt = new UserUpdater();
          upt.Id = user.userId;
          upt.Values = [];

          const pin = new KeyValue();
          pin.Key = 'pin';
          pin.Value = this.NewPin;
          upt.Values.push(pin);

          // tslint:disable-next-line: deprecation
          this.userService.putUser(user.userId, upt).subscribe(() => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.PINCHANGED').subscribe((resp: string) => {
              this.notificationSvc.info('', resp, 3000);
            });
            this.location.back();
            this.loading = false;
          }, err => {
            this.handleError(err);
          });
        }
      }
    }
  }


  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  handleError(err) {
    this.loading = false;
    if (err !== undefined && err != null && err.error !== undefined && err.error != null) {
      if (err.error.Message !== undefined && err.error.Message != null
        && err.error.Message === 'Incorrect password') {
          // tslint:disable-next-line: deprecation
          this.translate.get('ALERT.INCORRECTCURRENTPIN').subscribe((res: string) => {
            this.confirm(res);
          });
        } else {
          console.log(err);
        }
    } else {
      console.log(err);
    }
  }

  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

}
