import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Urls } from 'src/app/Models/Urls';
import { tap, catchError } from 'rxjs/operators';
import { UserUpdater } from 'src/app/Models/KeyValue';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = new Urls();
  usmUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmUrl = this.url.usmUrl;
  }

  getExternalUsers(): Observable<any> {
    const url = this.usmUrl + '/api/externalusers';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getExternalUserById(userId: string): Observable<any> {
    const url = this.usmUrl + '/api/externalusers/' + userId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUser(empId: number, uid: number, email: string, phone: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/users/' + uid + '/' + empId + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserInfo(uid, empid, email, phone) {
    // tslint:disable-next-line: max-line-length
    const url = this.url.usmUrl + '/api/users/ ' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getOrganisations() {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/organisations';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserByEmployeeIds(empId: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/users/0/' + empId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserByUserIds(uId: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/users/' + uId + '/0';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  checkUser(orgid: number, eid: number, email: string, phone: string, pass: string, pin: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/users/' + orgid + '/' + eid + '/' + email + '/' + phone + '/' + pass + '/' + pin;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserRoles(uid: number, gid: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/userroles/' + uid + '/' + gid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroupsByGroupIdUserId(orgid: number, gid: number, uid: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/usergroups/Get_ByUserId/' + orgid + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  putUser(id: number, upt: UserUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put(this.usmUrl + '/api/users/' + id, upt, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    console.log(error);
    throw error;
    return error;
  }

}
