import { Injectable } from '@angular/core';

@Injectable()
export class Data {

    public instance: any;
    public instances: any;

    public constructor() { }

}
