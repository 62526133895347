// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // keycloakUrl: 'http://localhost:8080',
  // // baseUrl: 'http://144.91.108.116:3001',
  // baseUrl: 'http://169.254.80.80:3001',
  // // baseUrl: 'https://localhost:44321',
  // appUrl: 'http://localhost:4220',
  // usmUrl: 'http://169.254.80.80:3011',
  // // usmUrl: 'http://144.91.108.116:3011',
  // // usmUrl: 'https://localhost:44328',
  // domain: 'localhost',
  // useKeycloak: 'false',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // flmApiUrl: 'http://localhost:3081'

  production: true,
  keycloakUrl: window["env"]["KEYCLOAKURL"] || "default",
  baseUrl: window["env"]["APIURL"] || "default",
  appUrl: window["env"]["APPURL"] || "default",
  usmUrl: window["env"]["USMURL"] || "default",
  domain: window["env"]["DOMAIN"] || "default",
  useKeycloak: window["env"]["USEKEYCLOAK"] || "default",
  realm: window["env"]["REALM"] || "default",
  clientid: window["env"]["CLIENTID"] || "default",
  flmApiUrl: window["env"]["FLMAPIURL"] || "default"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
