import { Injectable } from '@angular/core';
import { User } from 'src/app/Models/User';
import { Urls } from 'src/app/Models/Urls';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor(
  private keycloakService: KeycloakService
) { }

  public login(userInfo: User) {
    localStorage.setItem('ACCESS_TOKEN', 'access_token');
  }

  public isLoggedIn() {
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  public logout() {
    const url = new Urls();
    localStorage.removeItem('ACCESS_TOKEN');
    this.keycloakService.logout(url.appUrl + '/');
  }

  public clear() {
    localStorage.removeItem('ACCESS_TOKEN');
  }

}
