import { InstanceUpdater } from './../../Models/KeyValue';
import { AppInstance } from './../../Models/AppInstance';
import { UserAppInstance } from './../../Models/UserAppInstance';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, tap, map } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { Instance } from 'src/app/Models/Instance';
import { Urls } from 'src/app/Models/Urls';

@Injectable({
  providedIn: 'root'
})
export class UserappinstanceService {
  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getUserAppInstances(): Observable<any> {
    const url = this.baseUrl + '/api/userappinstance/Get_Instances';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserAppInstancesPageSize(page: number, size: number): Observable<any> {
    const url = this.baseUrl + '/api/userappinstance/Get_Instances/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserAppInstancesByUser(userId: string): Observable<any> {
    const url = this.baseUrl + '/api/userappinstance/Get_InstancesByUserId/' + userId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserAppInstancesByGroup(groupId: string): Observable<any> {
    groupId = groupId.replace('/', '');
    const url = this.baseUrl + '/api/userappinstance/Get_InstancesByGroupId/' + groupId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postAppInstance(inst: Instance): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.baseUrl + '/api/userappinstance', inst, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putAppInstance(inst: InstanceUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.baseUrl + '/api/userappinstance/' + inst.Id, inst, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteAppInstance(id: number): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.baseUrl + '/api/userappinstance/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }
}
