import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Models/Urls';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  url = new Urls();
  usmUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmUrl = this.url.usmUrl;
  }

  getGroups() {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const extUser = usr;
    this.orgId = extUser.organisationId;
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/groups/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroupByGroupId(id: number) {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const extUser = usr;
    this.orgId = extUser.organisationId;
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/groups/' + this.orgId + '/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroups(gid, uid) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/usergroups/Get_ByUserId/' + orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroupById(id: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/groups/' + orgId + '/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroupsByOrganisation(orgid) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmUrl + '/api/groups/' + orgid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
