import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './null.component.html',
  styleUrls: ['./null.component.css']
})
export class NullComponent implements OnInit {

  infoText = 'Information';
  closeText = 'Close';

  constructor(
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private keycloakService: KeycloakService
  ) {
      // translate.addLangs(['English', 'French']);
      // translate.setDefaultLang('English');

      // let browserLang = translate.getBrowserLang();
      // if (localStorage.getItem('language') !== null) {
      //   browserLang = localStorage.getItem('language');
      // }
      // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      // this.translate.get('ALERT.BROWSERCHECK').subscribe((resp) => {
      //   this.confirm(resp);
      // });
   }

  ngOnInit() {
    this.confirm('Browser doesn\'t fulfill the browser requirements:' +
           ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

}
