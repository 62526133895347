import { ImageService } from './../../Services/image/image.service';
import { KeyValue } from 'src/app/Models/KeyValue';
import { ImageUpdater } from './../../Models/KeyValue';
import { Location } from '@angular/common';
import { Home1Component } from './../home1/home1.component';
import { AppComponent } from './../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { PortalInfoService } from './../../Services/portal-info/portal-info.service';
import { PortalInfo } from './../../Models/PortalInfo';
import { Component, OnInit, Injector } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-portal-info',
  templateUrl: './portal-info.component.html',
  styleUrls: ['./portal-info.component.css']
})
export class PortalInfoComponent implements OnInit {

  portalname: string;
  portalHeaderColor = 'transparent';
  portalid: number;
  isSave = false;


  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();
  organisation: any;

  loading = false;
  items: MenuItem[];
  isAccount = false;
  isLanguage: boolean;

  icon: any;
  Icon: any;
  IconName: any;

  image: any;
  Image: any;
  ImageName: any;
  HeaderColor: any;

  hicon: any;

  constructor(
    private portalinfo: PortalInfoService,
    private translate: TranslateService,
    private appcomp: AppComponent,
    private home: Home1Component,
    private titleService: Title,
    // private keycloakService: KeycloakService,
    private router: Router,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private injector: Injector,
    private auth: AuthService,
    private domSanitizer: DomSanitizer,
    private imageService: ImageService,
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));

          this.titleService.setTitle(res[i].portalTitle);
          this.HeaderColor = res[i].portalHeaderColor;
          this.portalHeaderColor = res[i].portalHeaderColor;
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);
      this.HeaderColor = localStorage.getItem('headercolor');
      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.hicon = appcomp.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

      // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.isManage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

    this.loadUserProfile();

    this.portalname = localStorage.getItem('portal-title');
    // tslint:disable-next-line: radix
    this.portalid = parseInt(localStorage.getItem('portal-id'));
    this.getImages();
  }

  ngOnInit() {
  }

  getImages() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.imageService.getImages().subscribe((res) => {
      if (res !== null) {
        for (const img of res) {
          if (img.name === 'icon') {
            // this.icon = img.img;
            this.icon = this.domSanitizer.bypassSecurityTrustUrl(img.img);
            this.Icon = img.img;
          } else if (img.name === 'image') {
           // this.image = img.img;
           this.image = this.domSanitizer.bypassSecurityTrustUrl(img.img);
           this.Image = img.img;
         }
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  save() {
    this.isSave = true;
    this.loading = true;

    if (this.portalname === undefined || this.portalname == null || this.portalname.trim() === '') {
      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.PROVIDEAPPTITLE').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
      this.isSave = false;
      this.loading = false;
    } else if (this.Icon === undefined || this.Icon === null || this.Icon.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTORGANISATIONICON').subscribe((res: string) => {
        this.confirm(res);
      });
      this.isSave = false;
      this.loading = false;
    } else if (this.Image === undefined || this.Image === null || this.Image.toString().trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTORGANISATIONIMAGE').subscribe((res: string) => {
        this.confirm(res);
      });
      this.isSave = false;
      this.loading = false;
    } else {
      const pi = new PortalInfo();
      pi.PortalId = this.portalid;
      pi.PortalTitle = this.portalname;
      pi.PortalHeaderColor = this.HeaderColor;

      const img = new ImageUpdater();
      img.Name = 'general';
      img.Values = [];

      const icon = new KeyValue();
      icon.Key = 'icon';
      icon.Value = this.Icon;
      img.Values.push(icon);

      const image = new KeyValue();
      image.Key = 'image';
      image.Value = this.Image;
      img.Values.push(image);

      // tslint:disable-next-line: deprecation
      this.imageService.putImage(img).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.portalinfo.putPortalInfo(pi).subscribe(() => {
          localStorage.setItem('portal-title', this.portalname);
          localStorage.setItem('headercolor', this.HeaderColor);
          this.title = this.portalname;
          this.appcomp.title = this.portalname;
          this.home.title = this.portalname;

          this.appcomp.icon = this.icon;
          this.hicon = this.icon;

          this.portalHeaderColor = this.HeaderColor;

          this.titleService.setTitle(this.portalname);
          let alrt: string;
          // tslint:disable-next-line: deprecation
          this.translate.get('ALERT.SAVED').subscribe((res: string) => {
            alrt = res;
            this.notificationSvc.info('', alrt, 2000);
            this.loading = false;
          });
          this.isSave = false;
        }, err => this.handleError(err));
      }, err => this.handleError(err));
    }
  }

  onIconSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.IconName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Icon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.IconName = '';
        this.Icon = '';
        this.icon = null;
      });
    }
  }

  onImageSelected() {
    const inputNode: any = document.querySelector('#file2');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.ImageName = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Image = base64;
        this.image = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.ImageName = '';
        this.Image = '';
        this.image = null;
      });
    }
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
  }










  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }
}
