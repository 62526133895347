import { Icons } from './../../Models/Icons';
import { IconUpdater } from './../../Models/KeyValue';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Models/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getIcons() {
    return this.httpclient.get<any>(this.baseUrl + '/api/icons')
                          .pipe(catchError(this.handleError));
  }

  postIcon(icon: Icons) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.baseUrl + '/api/icons', icon, options)
                          .pipe(catchError(this.handleError));
  }

  putIcon(icon: IconUpdater) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.baseUrl + '/api/icons/' + icon.Id, icon, options)
                          .pipe(
                            catchError(this.handleError)
                          );
  }

  deleteIcon(id: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete(this.baseUrl + '/api/icons/' + id, options)
                          .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    throw error;
    return error;
  }
}
