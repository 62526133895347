import { Language } from './../../Models/language';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Models/Urls';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getLanguage(uid: number) {
    return this.httpclient.get<any>(this.baseUrl + '/api/language/' + uid)
                          .pipe(catchError(this.handleError));
  }

  postLanguage(lang: Language) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.baseUrl + '/api/language', lang, options)
                          .pipe(catchError(this.handleError));
  }

  putLanguage(lang: Language) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.baseUrl + '/api/language/' + lang.UserId, lang, options)
                          .pipe(
                            catchError(this.handleError)
                          );
  }

  deleteLanguage(uid) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete(this.baseUrl + '/api/language/' + uid, options)
                          .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }

}
