import { AuthService } from './../../Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/Services/application/app.service';
import { App } from 'src/app/Models/App';
import { KeycloakService } from 'keycloak-angular';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { Title } from '@angular/platform-browser';
import { Home1Component } from '../home1/home1.component';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { UsersService } from 'src/app/Services/users/users.service';
import { CookieService } from 'ngx-cookie-service';
import { AppUpdater, KeyValue } from 'src/app/Models/KeyValue';
import { UpperCasePipe } from '@angular/common';
import { Location } from '@angular/common';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css']
})
export class AppDetailComponent implements OnInit {

  appName: string;

  AppId: number;
  AppName: string;
  AppUrl: string;
  AppConfig: string;

  isAlert = false;
  loading = false;
  isCreate = false;
  isCancel = false;

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  isLanguage: boolean;
  http: any;

  urls = new Urls();
  organisation: string;

  items: MenuItem[];

  isAccount = false;

  portalHeaderColor = 'transparent';

  icon: any;

  constructor(
    private router: Router,
    private appservice: AppService,
    private translate: TranslateService,
    // private keycloakService: KeycloakService,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private userService: UsersService,
    private home: Home1Component,
    private cookieService: CookieService,
    private location: Location,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private injector: Injector,
    private auth: AuthService,
    private app: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.icon = app.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

      // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            // const usr = JSON.parse(localStorage.getItem('ext_user'));
            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

    this.loadUserProfile();

    this.getApp();
   }

  ngOnInit() {
  }

  onCancel() {
    this.loading = true;
    this.isCancel = true;
    // this.router.navigateByUrl('/manageapps');
    this.location.back();
  }

  Save() {
    this.loading = true;
    this.isCreate = true;

    // tslint:disable-next-line: max-line-length
    if (this.AppName === undefined || this.AppName == null || this.AppName.trim() === '') {
      // this.isAlert = true;
      this.loading = false;
      this.isCreate = false;

      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERAPPNAME').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    // tslint:disable-next-line: max-line-length
    } else if (this.AppUrl === undefined || this.AppUrl === null || this.AppUrl.trim() === '') {
      // this.isAlert = true;
      this.loading = false;
      this.isCreate = false;

      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERAPPURL').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    } else {
      this.isAlert = false;

      const app = new AppUpdater();
      app.Id = this.AppId;
      app.Values = [];

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = this.AppName;
      app.Values.push(name);

      const appurl = new KeyValue();
      appurl.Key = 'app_url';
      appurl.Value = this.AppUrl;
      app.Values.push(appurl);

      const config = new KeyValue();
      config.Key = 'config';
      config.Value = this.AppConfig;
      app.Values.push(config);

      let check = false;

      // tslint:disable-next-line: deprecation
      this.appservice.getApplications().subscribe((resp) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].appName === this.AppName && this.AppName !== this.appName) {
            check = true;
          }
        }
        if (check === true) {
          let alrt: string;
          // tslint:disable-next-line: deprecation
          this.translate.get('ALERT.APPALREADYEXISTS').subscribe((res: string) => {
            alrt = res;
            this.confirm(alrt);
          });
          this.loading = false;
          this.isCreate = false;
        } else {
          // tslint:disable-next-line: deprecation
            this.appservice.putApp(app).subscribe(() => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.APPUPDATED').subscribe((respp) => {
                this.notificationSvc.info('', respp, 2000);
                // this.router.navigateByUrl('/manageapps');
                this.location.back();
                this.loading = false;
                this.isCreate = false;
              });
          });
        }
      });
    }
  }

  getApp() {
    const ap = localStorage.getItem('app_detail');
    const app = JSON.parse(ap);
    this.appName = app.appName;
    this.AppId = app.appId;
    this.AppName =  app.appName;
    this.AppUrl = app.appUrl;
    this.AppConfig = app.appConfig;
  }


  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const extUser = JSON.parse(eu);

        if (va !== null) {
          // const extUser = JSON.parse(localStorage.getItem('ext_user'));

          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      // const user = JSON.parse(localStorage.getItem('ext_user'));
      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

  handleError(err) {
    this.loading = false;
  }

}
