import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Models/Urls';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.usmUrl;
  }

getOrganisations() {
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/organisations';
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

getOrganisationsPageSize(page: number, size: number) {
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/organisations/' + page + '/' + size;
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

private handleError(error: any) {
  // console.error('error', error);
  throw error;
  return error;
}

}
