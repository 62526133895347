import { AppUpdater } from './../../Models/KeyValue';
import { Urls } from './../../Models/Urls';
import { App } from '../../Models/App';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = this.url.baseUrl;
  }

  getApplications() {
    const url = this.baseUrl + '/api/applications';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getApplicationById(id: number) {
    const url = this.baseUrl + '/api/applications/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getApplicationsPageSize(page: number, size: number) {
    const url = this.baseUrl + '/api/applications/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postApp(app: App): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post<any>(this.baseUrl + '/api/applications', app, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putApp(app: AppUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.baseUrl + '/api/applications/' + app.Id, app, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteApp(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.baseUrl + '/api/applications/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
