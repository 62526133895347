import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/Services/application/app.service';
import { KeycloakService } from 'keycloak-angular';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { Title } from '@angular/platform-browser';
import { Home1Component } from '../home1/home1.component';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api/menuitem';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-manage-apps',
  templateUrl: './manage-apps.component.html',
  styleUrls: ['./manage-apps.component.css']
})
export class ManageAppsComponent implements OnInit {

  apps = [];

  isLoadMore = true;
  page = 1;
  size = 100;

  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();
  organisation: any;

  items: MenuItem[];
  options = [];
  isAccount = false;
  isLanguage: boolean;

  load = false;

  isperm = false;

  portalHeaderColor = 'transparent';

  icon: any;

  constructor(
    private router: Router,
    private appservice: AppService,
    private translate: TranslateService,
    // private keycloakService: KeycloakService,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private home: Home1Component,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private injector: Injector,
    private auth: AuthService,
    private app: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.icon = app.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

    // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.isManage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.EDIT').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DELETE').subscribe((resp2: string) => {
        this.options = [
          { label: resp1, value: 'Edit' },
          { label: resp2, value: 'Delete' }
        ];
      });
    });

    this.loadUserProfile();
   }

  ngOnInit() {
    localStorage.removeItem('app_detail');
    this.getApps();
  }

  createApp() {
    this.router.navigateByUrl('/createapp');
  }

  getApps() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.appservice.getApplicationsPageSize(this.page, this.size).subscribe((apps) => {
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < apps.length; j++) {
        apps[j].config = apps[j].appConfig;
        if (apps[j].appConfig.trim() === '') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.NOCONFIGURATION').subscribe((res) => {
            apps[j].config = res;
          });
        }
        apps[j].options = this.options;
        this.apps.push(apps[j]);
      }
      if (apps.length < this.size) {
        this.isLoadMore = true;
      } else {
        this.isLoadMore = false;
      }
      this.load = false;
      /*this.apps.sort((a, b) => {
        if (a.appName < b.appName) { return -1; }
        if (a.appName > b.appName) { return 1; }
        return 0;
      });*/
    }, err => this.handleError(err));
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  saveApp(event, app) {
    if (event.value === 'Edit') {
      localStorage.setItem('app_detail', JSON.stringify(app));
      this.router.navigateByUrl('/appdetail');
    } else if (event.value === 'Delete') {
      this.deleteApp(app);
    }
  }

  deleteApp(app) {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.appservice.deleteApp(app.appId).subscribe((resp) => {
      if (resp === true) {
        let alrt: string;
        // tslint:disable-next-line: deprecation
        this.translate.get('ALERT.DELETED').subscribe((res: string) => {
          alrt = res;
          this.notificationSvc.info('', alrt, 2000);
        });
        this.apps = [];
        this.getApps();
      } else {
        this.load = false;
        let alrt: string;
        // tslint:disable-next-line: deprecation
        this.translate.get('ALERT.COULDNOTDELETEAPP').subscribe((res: string) => {
          alrt = res;
          this.confirm(alrt);
        });
      }
    }, err => this.handleError(err));
  }

  loadMore() {
    this.page += 1;
    this.getApps();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.Message === '23503: update or delete on table "t_portal_app" violates foreign key constraint "t_portal_app_inst_app_id_fkey" on table "t_portal_app_inst"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.APPHASINSTANCES').subscribe((res) => {
        this.confirm(res);
      });
    }
  }







  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }

}
