import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Urls } from 'src/app/Models/Urls';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {
  url = new Urls();
  usmUrl: string;

  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmUrl = this.url.usmUrl;
  }

  getUserGroupsByUserId(id: number): Observable<any> {
    const v = new Variables();
    const va = localStorage.getItem('eu');
    const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(eu);

    const extUser = usr;
    this.orgId = extUser.organisationId;
    const url = this.usmUrl + '/api/usergroups/Get_ByUserId/' + this.orgId + '/0/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    console.log(error);
    throw error;
    return error;
  }

}
