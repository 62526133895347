export class KeyValue {
    public Key: string;
    public Value: string;
}

export class AppUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class InstanceUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class IconUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class UserUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class ImageUpdater {
    public Name: string;
    public Values: KeyValue[];
}
