import { Location } from '@angular/common';
import { KeyValue } from './../../Models/KeyValue';
import { TranslateService } from '@ngx-translate/core';
import { GroupInstance } from './../../Models/GroupInstance';
import { UserInstance } from './../../Models/UserInstance';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { UserappinstanceService } from 'src/app/Services/userappinstnce/userappinstance.service';
import { Component, OnInit, Injector } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { Instance } from 'src/app/Models/Instance';
import { AppService } from 'src/app/Services/application/app.service';

import * as svg from 'save-svg-as-png';
import { PortalInfoService } from 'src/app/Services/portal-info/portal-info.service';
import { Home1Component } from '../home1/home1.component';
import { KeycloakProfile } from 'keycloak-js';
import { Urls } from 'src/app/Models/Urls';
import { CookieService } from 'ngx-cookie-service';
import { InstanceUpdater } from 'src/app/Models/KeyValue';
import { MenuItem } from 'primeng/api/menuitem';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { GroupInstanceService } from 'src/app/Services/group-instance/group-instance.service';
import { IconsService } from 'src/app/Services/icons/icons.service';
import { AuthService } from 'src/app/Services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';
import { OrganisationService } from 'src/app/Services/organisation/organisation.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-instance-detail',
  templateUrl: './instance-detail.component.html',
  styleUrls: ['./instance-detail.component.css']
})
export class InstanceDetailComponent implements OnInit {

  instanceId: number;
  instanceName: string;
  filename: string;

  alertMessage = 'check information';

  isAlert = false;
  loading = false;
  isCreate = false;
  isCancel = false;

  AppId: number;
  AppName: string;
  AppUrl: string;
  AppConfig = '';
  InstanceId: number;
  InstanceName: string;
  InstanceDescription: string;
  InstanceTarget: string;
  InstanceIcon: any;
  InstanceParameters = '';
  icon: any;

  apps = [];
  app: any;

  targets = [];
  target: any;


  tempgrps = [];


  title: string;
  fullName: string;

  instances = [];

  userDetails: KeycloakProfile;
  isManage: boolean;
  http: any;

  urls = new Urls();
  organisation: any;

  items: MenuItem[];
  groups1 = [];
  groups2 = [];

  grps = [];

  icons = [];
  display = false;
  isAccount = false;
  isLanguage: boolean;

  load = false;

  organisations = [];
  org: any;

  portalHeaderColor = 'transparent';

  hicon: any;

  constructor(
    private router: Router,
    // private keycloakService: KeycloakService,
    private userappinstanceservice: UserappinstanceService,
    private appservice: AppService,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private portalinfo: PortalInfoService,
    private titleService: Title,
    private home: Home1Component,
    private cookieService: CookieService,
    private location: Location,
    private confirmationService: ConfirmationService,
    private notificationSvc: NotificationService,
    private groups: GroupsService,
    private groupinstance: GroupInstanceService,
    private iconsService: IconsService,
    private injector: Injector,
    private auth: AuthService,
    private orgService: OrganisationService,
    private appc: AppComponent
  ) {
    if (localStorage.getItem('portal-title') === null) {
      // tslint:disable-next-line: deprecation
      portalinfo.getPortalInfo().subscribe((res) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].portalTitle;
          localStorage.setItem('portal-title', res[i].portalTitle);
          localStorage.setItem('portal-id', JSON.stringify(res[i].portalId));
          this.portalHeaderColor = res[i].portalHeaderColor;
          localStorage.setItem('headercolor', res[i].portalHeaderColor);

          this.titleService.setTitle(res[i].portalTitle);
        }
      });
    } else {
      this.title = localStorage.getItem('portal-title');
      this.titleService.setTitle(this.title);

      this.portalHeaderColor = localStorage.getItem('headercolor');
    }

    this.hicon = appc.icon;

    // translate.addLangs(['English', 'French']);
    // translate.setDefaultLang('English');

    // let browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }
    // translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.use('English');

      // tslint:disable-next-line: deprecation
    this.translate.get('MANAGE.APPS').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('MANAGE.INSTANCES').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.MANAGEPORTALINFO').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ICONS').subscribe((resp4: string) => {
            this.items = [];
            this.items.push({ label: resp1, routerLink: ['/manageapps'] });
            this.items.push({ label: resp2, routerLink: ['/manageinstances'] });

            const v = new Variables();
            const va = localStorage.getItem('eu');
            const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
            const usr = JSON.parse(eu);

            const og = usr.organisationName;
            if (og === 'ORG_SUPER_ADMIN') {
              this.isLanguage = true;
              this.isManage = true;
              this.items.push({ label: resp3, routerLink: ['/portalinfo'] });
            }
            this.items.push({ label: resp4, routerLink: ['/icons'] });
          });
        });
      });
    });

      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.CONTENTOPTION').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.NEWTABOPTION').subscribe((resp2: string) => {
        this.targets = [
          {
            label: resp1, value: 'content'
          },
          {
            label: resp2, value: 'new_tab'
          }
        ];
      });
    });

    this.loadUserProfile();

      // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NOFILESELECTED').subscribe((res: string) => {
      this.filename = res;
    });
    this.load = true;
    // tslint:disable-next-line: deprecation
    appservice.getApplications().subscribe((apps) => {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < apps.length; i++) {
        this.apps.push(apps[i]);
      }

      this.setInstance();

      this.load = false;
    }, err => this.handleError(err));

    this.getOrganisations();

    this.getIcons();
  }

  ngOnInit() {
  }

  setInstance() {
    const inst = localStorage.getItem('inst_detail');
    const instance = JSON.parse(inst);
    this.instanceId = instance.instanceId;
    this.instanceName =  instance.instanceName;

    this.InstanceId = instance.instanceId;
    this.InstanceName =  instance.instanceName;
    this.InstanceDescription = instance.instanceDescription;
    this.InstanceParameters = instance.instanceParameters;
    this.AppId = instance.appId;
    this.InstanceTarget = instance.instanceTarget;
    this.target = instance.instanceTarget;
    this.icon = this.domSanitizer.bypassSecurityTrustUrl(instance.instanceIcon);
    this.InstanceIcon = instance.instanceIcon;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.apps.length; i++) {
      if (this.apps[i].appId === instance.appId) {
        this.AppName = this.apps[i].appName;
        this.AppId = instance.appId;
      }
    }
  }

  async getOrganisations() {
    this.loading = true;
    const orgs = await this.orgService.getOrganisations().toPromise().catch(err => this.handleError(err));
    if (orgs != null && orgs.length > 0) {
      for (const val of orgs) {
        this.organisations.push({ label: val.organisationName, value: val });
      }
    }
    this.loading = false;
  }

  get() {
    this.getGroups();
  }

  getGroups() {
    this.groups1 = [];
    this.groups2 = [];
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.groups.getGroupsByOrganisation(this.org.organisationId).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.groups1.push(res[i]);
        }
        this.getInstance();
      } else {
        this.getInstance();
      }
    }, err => this.handleError(err));
  }

  changePass() {
    this.router.navigateByUrl('/change-password');
  }

  changePin() {
    this.router.navigateByUrl('/change-pin');
  }

  getIcons() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.iconsService.getIcons().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].saveicon = this.domSanitizer.bypassSecurityTrustUrl(res[i].icon);
          this.icons.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  select(icon) {
    this.display = false;
    this.icon = icon.saveicon;
    this.filename = icon.iconName;
    this.InstanceIcon = icon.icon;
  }

  showIcons() {
    this.display = true;
  }

  Cancel() {
    this.loading = true;
    this.isCancel = true;
    // this.router.navigateByUrl('/manageinstances');
    this.location.back();
  }

  Save() {
    this.loading = true;
    this.isCreate = true;

    if (this.InstanceName === undefined || this.InstanceName === null || this.InstanceName.trim() === '') {
      this.loading = false;
      this.isCreate = false;
      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERINSTANCENAME').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    } else if (this.InstanceDescription === undefined || this.InstanceDescription === null || this.InstanceDescription.trim() === '') {
      this.loading = false;
      this.isCreate = false;
      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.ENTERINSTANCEDESCRIPTION').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    } else if (this.target === undefined || this.target === null || this.target.trim() === '') {
      this.loading = false;
      this.isCreate = false;
      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.SELECTTARGET').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    } else if (this.InstanceIcon === undefined || this.InstanceIcon === null || this.InstanceIcon.trim() === '') {
      this.loading = false;
      this.isCreate = false;
      let alrt: string;
      // tslint:disable-next-line: deprecation
      this.translate.get('ALERT.SELECTICON').subscribe((res: string) => {
        alrt = res;
        this.confirm(alrt);
      });
    } else {
      this.isAlert = false;

      const ai = new InstanceUpdater();
      ai.Id = this.instanceId;
      ai.Values = [];

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = this.InstanceName;
      ai.Values.push(name);

      const desc = new KeyValue();
      desc.Key = 'desc';
      desc.Value = this.InstanceDescription;
      ai.Values.push(desc);

      const target = new KeyValue();
      target.Key = 'target';
      target.Value = this.target;
      ai.Values.push(target);

      const params = new KeyValue();
      params.Key = 'params';
      params.Value = this.InstanceParameters;
      ai.Values.push(params);

      const icon = new KeyValue();
      icon.Key = 'icon';
      icon.Value = this.InstanceIcon;
      ai.Values.push(icon);

      const iconname = new KeyValue();
      iconname.Key = 'icon_name';
      iconname.Value = this.filename;
      ai.Values.push(iconname);

      let check = false;
      // tslint:disable-next-line: deprecation
      this.userappinstanceservice.getUserAppInstances().subscribe((resp) => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].instanceName === this.InstanceName && this.InstanceName !== this.instanceName) {
            check = true;
          }
        }

        if (check === true) {
          let alrt: string;
          // tslint:disable-next-line: deprecation
          this.translate.get('ALERT.INSTANCEALREADYEXISTS').subscribe((res: string) => {
            alrt = res;
            this.confirm(alrt);
          });
          this.loading = false;
          this.isCreate = false;
        } else {
          // tslint:disable-next-line: deprecation
            this.userappinstanceservice.putAppInstance(ai).subscribe(() => {
              if (this.tempgrps.length > 0) {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.tempgrps.length; i++) {
                  const ginst = new GroupInstance();
                  ginst.InstanceId = this.instanceId;
                  ginst.Group = this.tempgrps[i];
                  // tslint:disable-next-line: deprecation
                  this.groupinstance.deleteGroupInstance(JSON.stringify(ginst)).subscribe(() => {
                    if ((i + 1) === this.tempgrps.length) {
                      this.update();
                    }
                  });
                }
              } else {
                this.update();
              }
          });
        }
      });
    }
  }

  update() {
    if (this.groups2.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.groups2.length; i++) {
        const ginst = new GroupInstance();
        ginst.InstanceId = this.instanceId;
        // ginst.Group = this.groups2[i].groupName;
        ginst.Group = this.groups2[i].groupId.toString();
        // tslint:disable-next-line: deprecation
        this.groupinstance.postGroupInstance(ginst).subscribe(() => {});
      }
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INSTANCEUPDATED').subscribe((respp) => {
        this.notificationSvc.info('', respp, 2000);
        // this.router.navigateByUrl('/manageinstances');
        this.location.back();
        this.loading = false;
        this.isCreate = false;
      });
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INSTANCEUPDATED').subscribe((respp) => {
        this.notificationSvc.info('', respp, 2000);
        // this.router.navigateByUrl('/manageinstances');
        this.location.back();
        this.loading = false;
        this.isCreate = false;
      });
    }
  }

  getInstance() {
    this.loading = true;
    const inst = localStorage.getItem('inst_detail');
    const instance = JSON.parse(inst);
    this.instanceId = instance.instanceId;
    this.instanceName =  instance.instanceName;

    this.InstanceId = instance.instanceId;
    this.InstanceName =  instance.instanceName;
    this.InstanceDescription = instance.instanceDescription;
    this.InstanceParameters = instance.instanceParameters;
    this.AppId = instance.appId;
    this.InstanceTarget = instance.instanceTarget;
    this.target = instance.instanceTarget;
    this.icon = this.domSanitizer.bypassSecurityTrustUrl(instance.instanceIcon);
    this.InstanceIcon = instance.instanceIcon;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.apps.length; i++) {
      if (this.apps[i].appId === instance.appId) {
        this.AppName = this.apps[i].appName;
        this.AppId = instance.appId;
      }
    }

      // tslint:disable-next-line: deprecation
    this.groupinstance.getGroupInstances(this.instanceId).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.grps.push(res[i].group.toString());
        }
        const grp = [];
        this.tempgrps = [];
        let count = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.groups1.length; j++) {
          if (!this.grps.includes(this.groups1[j].groupId.toString())) {
            grp.push(this.groups1[j]);
          } else {
            this.groups2.push(this.groups1[j]);
            this.tempgrps.push(this.groups1[j].groupId.toString());
          }
          count += 1;
          if (count === this.groups1.length) {
            this.groups1 = grp;
          }
        }
        this.loading = false;
      }
    });
  }

  assignUser() {
    this.router.navigateByUrl('/assignuser');
  }

  assignGroup() {
    this.router.navigateByUrl('/assigngroup');
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.filename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.InstanceIcon = base64;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.InstanceIcon = '';
        this.icon = null;
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    this.loading = false;
  }



  gotoAccount() {
    const variables = new Variables();
    window.open(this.urls.keycloakUrl + '/auth/realms/' + variables.realm + '/account');
  }

  gotoLanguage() {
    this.router.navigateByUrl('/language');
  }

  logout() {
    localStorage.clear();

    this.cookieService.delete('goepla-portal-organisation-id');
    this.cookieService.delete('goepla-portal-user-id');
    this.cookieService.delete('goepla-portal-language');

    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.logout(this.urls.appUrl + '/');
    } else {
      this.auth.logout();
      // window.location.reload();
    }
  }

  async loadUserProfile() {
    if (environment.useKeycloak === 'true') {
      const keycloakService = this.injector.get(KeycloakService);
      keycloakService.loadUserProfile().then((user) => {
        this.fullName = user.firstName + ' ' + user.lastName;
        if (localStorage.getItem('fullname') != null) {
          this.fullName = localStorage.getItem('fullname');
        }

        const v = new Variables();
        const va = localStorage.getItem('eu');
        const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const usr = JSON.parse(eu);

        if (va !== null) {
          const extUser = usr;
          this.organisation = extUser.organisationName;
        }
      });
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const user = JSON.parse(eu);

      this.organisation = user.organisationName;
      this.fullName = user.firstName + ' ' + user.lastName;
    }
  }

  gotoManage() {
    this.router.navigateByUrl('/manage');
  }
}
