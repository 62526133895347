import { environment } from 'src/environments/environment';
import { Injectable, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    // private keycloakService: KeycloakService,
    private injector: Injector
  ) { }

  isManage = false;

  canActivate() {
    if (localStorage.getItem('browser') === null
        || localStorage.getItem('browser') === undefined
        || localStorage.getItem('browser') === 'false') {
          return false;
    } else {
      const v = new Variables();
      const va = localStorage.getItem('eu');
      const eu = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const usr = JSON.parse(eu);

      const og = usr.organisationName;
      if (og === 'ORG_SUPER_ADMIN') {
        return true;
      } else {
        return false;
      }
    }
  }
}
